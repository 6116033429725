@import './tailwind.css';

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  color: var(--color-marinaBlue-90);

  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--color-marinaBlue-70);
}

table {
  /* Necessary to remove TableV2 column header spacing */
  border-collapse: collapse;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Necessary to hide Google's reCAPTCHA badge. */
/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --font-family: var(--font-inter), 'Akkurat Pro', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;

  background-color: var(--color-marinaBlue-10);
  box-sizing: border-box;

  /*  Colors copied from */
  /* common/dist/_assets/postcss/index.js */
  /* usage var(--color-back); */
  --color-transparent: transparent;
  --color-black: #000;
  --color-white: #fff;
  /* 0D is 5% opacity */
  --color-marinaBlue-90-05: #e3e8f10d;
  /* 10% opacity */
  --color-marinaBlue-90-10: #e3e8f11a;
  /* 30% opacity */
  --color-marinaBlue-90-30: #e3e8f14d;

  --color-marinaBlue-10: #000000;
  --color-marinaBlue-10-rgb: 0, 0, 0;
  --color-marinaBlue-20: #222325;
  --color-marinaBlue-20-rgb: 34, 35, 37;
  --color-marinaBlue-30: #313235;
  --color-marinaBlue-30-rgb: 49, 50, 53;
  --color-marinaBlue-40: #3856a8;
  --color-marinaBlue-40-rgb: 56, 86, 168;
  --color-marinaBlue-50: #5580f6;
  --color-marinaBlue-50-rgb: 85, 128, 246;
  --color-marinaBlue-60: #90acf9;
  --color-marinaBlue-60-rgb: 144, 172, 249;
  --color-marinaBlue-70: #c0d0fc;
  --color-marinaBlue-70-rgb: 192, 208, 252;
  --color-marinaBlue-80: #e6edfe;
  --color-marinaBlue-80-rgb: 230, 237, 254;
  --color-marinaBlue-90: #ffffff;
  --color-marinaBlue-90-rgb: 255, 255, 255;

  --color-basaltGray-10: #161718;
  --color-basaltGray-10-rgb: 22, 23, 24;
  --color-basaltGray-20: #222325;
  --color-basaltGray-20-rgb: 34, 35, 37;
  --color-basaltGray-30: #313235;
  --color-basaltGray-30-rgb: 49, 50, 53;
  --color-basaltGray-40: #4e5055;
  --color-basaltGray-40-rgb: 78, 80, 85;
  --color-basaltGray-50: #76787f;
  --color-basaltGray-50-rgb: 118, 120, 127;
  --color-basaltGray-60: #a2a4a9;
  --color-basaltGray-60-rgb: 162, 164, 169;
  --color-basaltGray-70: #cacbce;
  --color-basaltGray-70-rgb: 202, 203, 206;
  --color-basaltGray-80: #e4e5e7;
  --color-basaltGray-80-rgb: 228, 229, 231;
  --color-basaltGray-90: #f4f5f5;
  --color-basaltGray-90-rgb: 244, 245, 245;

  --color-seafoamTeal-10: #000a09;
  --color-seafoamTeal-10-rgb: 0, 10, 9;
  --color-seafoamTeal-20: #001f1c;
  --color-seafoamTeal-20-rgb: 0, 31, 28;
  --color-seafoamTeal-30: #01463f;
  --color-seafoamTeal-30-rgb: 1, 70, 63;
  --color-seafoamTeal-40: #02796d;
  --color-seafoamTeal-40-rgb: 2, 121, 109;
  --color-seafoamTeal-50: #03bfac;
  --color-seafoamTeal-50-rgb: 3, 191, 172;
  --color-seafoamTeal-60: #5dd5c9;
  --color-seafoamTeal-60-rgb: 93, 213, 201;
  --color-seafoamTeal-70: #a2e6e0;
  --color-seafoamTeal-70-rgb: 162, 230, 224;
  --color-seafoamTeal-80: #d7f4f1;
  --color-seafoamTeal-80-rgb: 215, 244, 241;
  --color-seafoamTeal-90: #effaf9;
  --color-seafoamTeal-90-rgb: 239, 250, 249;

  --color-clownfishOrange-10: #301208;
  --color-clownfishOrange-rgb-10: 48, 18, 8;
  --color-clownfishOrange-20: #67240e;
  --color-clownfishOrange-rgb-20: 103, 36, 14;
  --color-clownfishOrange-30: #983616;
  --color-clownfishOrange-rgb-30: 152, 54, 22;
  --color-clownfishOrange-40: #ce491c;
  --color-clownfishOrange-rgb-40: 206, 73, 28;
  --color-clownfishOrange-50: #ff5b24;
  --color-clownfishOrange-rgb-50: 255, 91, 36;
  --color-clownfishOrange-60: #ff7d52;
  --color-clownfishOrange-rgb-60: 255, 125, 82;
  --color-clownfishOrange-70: #ff9c7a;
  --color-clownfishOrange-rgb-70: 255, 156, 122;
  --color-clownfishOrange-80: #ffddd1;
  --color-clownfishOrange-rgb-80: 255, 221, 209;
  --color-clownfishOrange-90: #fff0eb;
  --color-clownfishOrange-rgb-90: 255, 240, 235;

  --color-lobsterRed-10: #270205;
  --color-lobsterRed-10-rgb: 39, 2, 5;
  --color-lobsterRed-20: #42060b;
  --color-lobsterRed-20-rgb: 66, 6, 11;
  --color-lobsterRed-30: #5d0910;
  --color-lobsterRed-30-rgb: 93, 9, 16;
  --color-lobsterRed-40: #93101b;
  --color-lobsterRed-40-rgb: 147, 16, 27;
  --color-lobsterRed-50: #d5202f;
  --color-lobsterRed-50-rgb: 213, 32, 47;
  --color-lobsterRed-60: #e8646f;
  --color-lobsterRed-60-rgb: 232, 100, 111;
  --color-lobsterRed-70: #f1a7ad;
  --color-lobsterRed-70-rgb: 241, 167, 173;
  --color-lobsterRed-80: #f7d4d7;
  --color-lobsterRed-80-rgb: 247, 212, 215;
  --color-lobsterRed-90: #fceeef;
  --color-lobsterRed-90-rgb: 252, 238, 239;

  --color-submarineYellow-10: #271c02;
  --color-submarineYellow-10-rgb: 39, 28, 2;
  --color-submarineYellow-20: #614505;
  --color-submarineYellow-20-rgb: 97, 69, 5;
  --color-submarineYellow-30: #9b6f08;
  --color-submarineYellow-30-rgb: 155, 111, 8;
  --color-submarineYellow-40: #d0950b;
  --color-submarineYellow-40-rgb: 208, 149, 11;
  --color-submarineYellow-50: #ebb128;
  --color-submarineYellow-50-rgb: 235, 177, 40;
  --color-submarineYellow-60: #f0c35c;
  --color-submarineYellow-60-rgb: 240, 195, 92;
  --color-submarineYellow-70: #f5d894;
  --color-submarineYellow-70-rgb: 245, 216, 148;
  --color-submarineYellow-80: #fdecc4;
  --color-submarineYellow-80-rgb: 253, 236, 196;
  --color-submarineYellow-90: #fdf8ec;
  --color-submarineYellow-90-rgb: 253, 248, 236;

  --color-algaeGreen-10: #0a1f11;
  --color-algaeGreen-10-rgb: 2, 25, 0;
  --color-algaeGreen-20: #12361e;
  --color-algaeGreen-20-rgb: 18, 54, 30;
  --color-algaeGreen-30: #1a4d2b;
  --color-algaeGreen-30-rgb: 26, 77, 43;
  --color-algaeGreen-40: #297a44;
  --color-algaeGreen-40-rgb: 41, 122, 68;
  --color-algaeGreen-50: #3db866;
  --color-algaeGreen-50-rgb: 61, 184, 102;
  --color-algaeGreen-60: #79d297;
  --color-algaeGreen-60-rgb: 121, 210, 151;
  --color-algaeGreen-70: #b3e6c4;
  --color-algaeGreen-70-rgb: 179, 230, 196;
  --color-algaeGreen-80: #d9f2e1;
  --color-algaeGreen-80-rgb: 217, 242, 225;
  --color-algaeGreen-90: #f0faf3;
  --color-algaeGreen-90-rgb: 240, 250, 243;
}

.App {
  text-align: center;
  display: grid;
  grid-template-columns: 4rem auto;
  color: var(--color-white);
  text-align: left;
}

.App-main {
  padding: 24px;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body .submission-questionnaire-container {
  display: inline-flex;
  width: 880px;
  padding-top: 76px;
  padding-right: 80px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px 0px;
}

body .submission-questionnaire-heading-huge {
  align-self: stretch;
  color: var(--color-ref-text-dark-strong, #e3e8f1);

  /* Web / Heading / Huge 32 */
  /* font-family: Akkurat Pro */
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 44.8px;
  /* 44.8px */

  word-wrap: break-word;
}

body .submission-questionnaire-heading-medium {
  align-self: stretch;

  color: var(--color-ref-text-dark-strong, #e3e8f1);
  text-align: center;

  /* ref/web/heading/medium */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 150% */

  word-wrap: break-word;
}

body .submission-questionnaire-heading-small {
  align-self: stretch;

  color: var(--color-ref-text-dark-strong, #e3e8f1);

  /* ref/web/heading/small */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */

  word-wrap: break-word;
}

body .submission-questionnaire-body-regular {
  align-self: stretch;

  color: var(--comp-text-field-dark-label-strong, #e3e8f1);

  /* ref/web/body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */

  word-wrap: break-word;
  text-align: justify;
  text-justify: inter-word;
  display: table-row;
}

body .submission-questionnaire-title-regular {
  align-self: stretch;

  color: var(--comp-text-field-dark-label-strong, #e3e8f1);

  /* ref/web/body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */

  word-wrap: break-word;
}

body .submission-questionnaire-title-underline {
  align-self: stretch;

  color: var(--comp-text-field-dark-label-strong, #e3e8f1);

  /* ref/web/body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */

  text-decoration: underline;
  word-wrap: break-word;
}

body .submission-questionnaire-description-medium {
  align-self: stretch;
  color: var(--color-ref-text-dark-strong, #e3e8f1);

  /* ref/web/heading/small */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */

  word-wrap: break-word;
  text-align: justify;
  text-justify: inter-word;
  display: table-row;
}

body .submission-questionnaire-description-small {
  align-self: stretch;
  color: var(--color-ref-text-dark-strong, #e3e8f1);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */

  word-wrap: break-word;
  text-align: justify;
  text-justify: inter-word;
  display: table-row;
}

body .submission-questionnaire-body-idented {
  align-self: stretch;

  color: var(--comp-text-field-dark-label-strong, #e3e8f1);

  /* ref/web/body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  padding-left: 24px;
  word-wrap: break-word;
}

body .submission-questionnaire-table {
  border-collapse: collapse;
  width: 100%;
}

body .submission-questionnaire-table-td-th {
  border: 1px solid #e3e8f1;
  text-align: left;
  padding: 8px;
}
